.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* Jukebox */

.progress-bar {
  width: 100px;
  display:inline-block;
  padding: 5px;
}

.progress-bar-container {
  width: 150px;
  height: 50px;
  display:inline-block;
  padding: 0px 5px 0px 5px;
}
.progress-time {
  width: 75px;
  height: 30px;
  display:inline-block;
  padding: 0px 5px 0px 5px;
}


.marquee {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  margin: 0px;
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

.play-status {
  display: inline-block;
}

.volume-status {
  display: inline-block;
} 

.widget-dashboard {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  grid-auto-rows: minmax(120px, auto);
}

.datetime-widget {
  grid-column: 1/3;
  grid-row: 1;
}

.weather-widget {
  grid-column: 3/5;
  grid-row: 1;
}

.calendar-widget {
  grid-column: 5/10;
  grid-row: 1/3;
}

.bus-widget {
  grid-column: 5/10;
  grid-row: 3/4;
}

.slideshow-widget {
  grid-column: 1/5;
  grid-row: 2/4;
}

.discord-widget {
  grid-column: 1/5;
  grid-row: 4/5;
}

.jubebox-widget {
  grid-column: 5/10;
  grid-row: 4/5;
}

.widget {
  border-radius: 25px;
  border-style: solid;
  border-color: #fff;
  border-width: 3px;
  padding: 25px;
}

.datetime {
  margin: 0px;
}

.slideshow-img {
  width: 400px;
}